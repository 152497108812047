.land-header{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
}
.app-log{
    width: 40px;
    height: 40px;
    background-image: url(../../asset/images/muanaexchanhe-log-white.png);
    border-radius: 50%;
}
#sign{
    background-color: #4f05fd;
    color: #fff;
    border-radius: 10px;
    font-weight: 500;
}
@media screen and (max-width: 986px) {
    .land-header nav{ margin-left: 10px; margin-right: 10px;}
}

@media screen and (max-width: 912px) {
    .land-header.mobile{
        width: 100%;
        height: max-content;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: fixed;
        z-index: 1;
        top: 0;
        background-color: #fff;
        padding-bottom: 10px;
        transition: all ease .3s;
    }
    .land-header nav{ display: none;}
    .land-header.mobile.active{height: max-content; transition: all ease .3s;}
    .land-header.mobile nav{
        width: 95%;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
    }
    .land-header.mobile nav button{
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background-color: #ffffff;
        color: #4f05fd;
        font-size: 18pt;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .land-header.mobile main{ 
        display: flex; 
        transition: all ease .3s;
        background-color: #fff;
        width: 100%;
        display: none;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
        border-top: 1px solid #fff;
    }
    .land-header.mobile main.active{
        transition: all ease .3s;
        display: flex;
    }
    .land-header.mobile nav h1{
        font-weight: 600;
        font-size: 20pt;
    }
    .land-header.mobile main a{
        padding: 10px;
        color: gray;
        margin: 10px;
    }
    .land-header.mobile main a.active{
        border-bottom: 1px solid #4f05fd;
        font-weight: 500;
        color: #4f05fd;
    }
}
@media screen and (min-width: 912px) {
    .land-header.mobile{ display: none;}
    .land-header{
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        background-color: #ffffff;
    }
    .land-header nav{
        margin-left: 20px;
        margin-right: 20px;
    }
    .land-header nav.active{
        transition: all ease .3s;
        transform: translateY(0);
    }
    .land-header nav h1{
        font-weight: 600;
        font-size: 20pt;
    }
    .land-header nav a{
        padding: 10px;
        color: gray;
        margin: 10px;
    }
    .land-header nav a.active{
        border-bottom: 1px solid #4f05fd;
        font-weight: 500;
        color: #4f05fd;
    }
}