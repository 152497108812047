body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-snap-align: top;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  color: #000000;
  font-size: 11pt;
  margin: 0;
  padding: 0;
  outline: none;
  background-color: transparent;
  text-decoration: none;
  border: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  resize: none;
  font-weight: 400;
}
*::-webkit-scrollbar{ width: 0;}
*::-webkit-inner-spin-button{
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
input[type="file"]{
  font-size: 0;
  width: 0;
}

/*
* root-content
*/

.app-content{
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: auto;
}
