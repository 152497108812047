/*
*/
.land-banner{
    width: 100%;
    height: max-content;
    padding-top: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.land-banner .label{
    max-width: 600px;
    height: max-content;
}
.land-banner .label .h1{
    font-size: 35pt;
    font-weight: 600;
    width: 80%;
}
.land-banner .label h1 b{
    font-size: 35pt;
    color: #4f05fd;
    font-weight: 600;
}
.land-banner .label span{
    font-weight: 500;
}
.land-banner .label p{
    max-width: 60%;
}
.land-banner .label .download{
    font-weight: 500;
    width: max-content;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-right: 10px;
    transition: all ease .3s;
    background-color: #000;
    color: #fcf9f6;
}
.land-banner .label a i{ color: #fff;}
/*
*
*/
.land-service{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: 1.7rem;
}
.land-service .label{
    width: 350px;
    height: 260px;
    margin: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    transition: all ease .3s;
    box-shadow: 0 0 20px #4203d346;
    background-color: #fff;
}
.land-service .label:hover{
    box-shadow: 0 0 50px #4203d346;
    cursor: pointer;
}
.land-service .label div{
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50pt;
    border-radius: 50%;
    margin: 10px;
}
.land-service .label h1{
    font-size: 12pt;
    font-weight: 500;
    margin: 10px;
}
.land-service .label p{
    text-align: center;
    color: gray;
}
.land-service .label div.el-1{
    background-color: #bbdefa;
    color: #0289f8;    
}
.land-service .label div.el-2{
    background-color: #fceec7;
    color: #ecaf05;    
}
.land-service .label div.el-3{
    background-color: #bff7e9;
    color: #03bd8e;    
}
.land-service .label div.el-4{
    background-color: #f2dbfa;
    color: #a401df;    
}
.bi-country-suport{ 
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.bi-country-suport img{
    max-width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: 0 0 5px #0000001f;
}
/*
*/
.label-2{
    width: 80%;
}
.label-4{
    width: 80%;
    height: 100px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #e3eaff;
    margin-top: 20px;
    padding: 10px;
}
.label-4 div{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border-radius: 50%;
    background-color: #4f05fd;
    color: #fff;
    font-size: 18pt;
}
.label-4  h1{ font-weight: 500; font-size: 12pt; max-width: 80%;}
.label-4  p{ font-weight: 400; font-size: 10pt;}
/*
*/
.app-footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #4204d3;
    margin-top: 10px;
}
.app-footer nav{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    width: 80%;
}
.app-footer nav h1{
    color: #fff;
    margin-left: 10px;
}
.app-footer nav p{
    color: #cfcece;
    margin-left: 10px;
}
.app-footer nav span{
    color: #cfcece;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.app-footer nav div { width: 100%; display: flex; align-items: center;}
.app-footer nav div a{
    margin: 10px;
    padding: 10px;
    color: #cfcece;
}
/*
*
*/
.btn-suport{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #03bd8e;
    color: #fff;
    font-size: 18pt;
    position: fixed;
    bottom: 100px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px #00000038;
}
/*
*
*/
.conversor-main{
    width: 370px;
    display: flex;
    align-items: center;
    border-radius: 30px;
    border: 1px solid #1f1f1f;
    background-color: #0c0c0c;
}
.conversor{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen  and (max-width: 1500px) {
    #banner{
        display: flex;
    }
    .land-banner{
        width: 100%;
        height: max-content;
        padding-top: 7rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        
    }
    .land-banner .label{
        max-width: 500px;
        height: max-content;
        margin: 0;
    }
    .land-banner .label p{
        width: 90%;
    }
}
@media screen  and (max-width: 1050px) {
    .land-banner{
        justify-content: center;
    }
    .land-banner .label{
        max-width: 450px;
    }
    .land-banner .label p{
        max-width: 100%;
    }
}
@media screen  and (max-width: 900px) {
    .land-banner{
        flex-direction: column-reverse;
    }
    .land-banner .label{
        width: 100%;
    }
}
@media screen  and (max-width: 600px) {
    .label-4{ width: 88%; height: max-content;}
    .label-4 h1{ max-width: 70%;}
    .land-banner .label h1{
        width: 85%;
    }
    .land-banner{
        width: 100%;
        height: max-content;
        padding-top: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
    }
    .conversor-main{
        width: 95%;
        min-width: 150px;
    }
    
    .land-banner .label{
        width: 95%;
        height: max-content;
        margin: 0;
        padding-bottom: 10px;
    }
    .land-banner .label p{
        max-width: 100%;
    }
    .land-banner .label div a{ 
        width: 100px;
        margin-top: 10px;
    }
    .app-footer div{
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .btn-suport{ bottom: 50px;}

    
}
